<template>
    <div id="calculatorRate">
        <vue-final-modal
            v-model="showModal"
            classes="modal-container"
            content-class="modal-content"
            id="tbimodal"
        >
            <button
                type="button"
                class="close"
                aria-label="Close"
                @click="showModal = false"
            >
                <span aria-hidden="true">&times;</span>
            </button>
            <span class="modal__header">
                <h5 class="modal-title">
                    Cumpără în rate cu <img src="@/assets/images/logo-tbi.png" />
                </h5>
            </span>
            <div class="modal-body">
                <table class="table">
                    <thead class="table-head">
                        <tr class="">
                            <td class="cell">Banca</td>
                            <td class="cell">Rate</td>
                            <td class="cell">Rată lunară</td>
                            <td class="cell">Valoare totală</td>
                        </tr>
                    </thead>
                    <tbody id="rate-table-body" class="table-body">
                        <tr class="" v-for="rata in rates" :key="rata.period">
                            <td class="cell">Finantare 100% Online</td>
                            <td class="cell" style="font-family: 'Raleway Bold'">
                                {{ rata.period }}
                            </td>
                            <td
                                class="cell"
                                style="font-family: 'Raleway Bold'; color: red"
                            >
                                {{ rata.rata }} Lei
                            </td>
                            <td class="cell">{{ rata.total }} Lei</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div style="display: flex" class="desc-item">
                <div>
                    <img src="@/assets/images/noun-check.svg" />
                </div>
                <div>
                    După adăugarea produsului în coș,
                    <span style="color: #008c00; text-decoration: underline"
                        >la finalizarea comenzii</span
                    >, veți putea selecta aceasta modalitate de plată, ulterior fiind
                    contactat de unul din colegii noștri pentru primirea linkului în
                    vederea efectuării plații.
                </div>
            </div>

            <!-- <div class="modal-footer">
        <button @click="showModal = false" type="button" class="btn btn-red">Cumpără în rate cu TBI Credit</button>
      </div> -->
        </vue-final-modal>

        <!-- StarBtModal -->
        <vue-final-modal
            v-model="starbtModal"
            classes="modal-container"
            content-class="modal-content"
            id="starbtmodal"
        >
            <button
                type="button"
                class="close"
                aria-label="Close"
                @click="starbtModal = false"
            >
                <span aria-hidden="true">&times;</span>
            </button>
            <span class="modal__header">
                <h5 class="modal-title">
                    Cumpără în 5 rate, fără dobândă, cu:
                </h5>
            </span>
            <div class="modal-body">
                <div class="banks">
                    <img src="@/assets/images/starbtcard.png" />
                    <img src="@/assets/images/cardavantaj.png" />
                    <img src="@/assets/images/garanti.png" />
                    <img src="@/assets/images/alphabank.png" />
                </div>
                <div class="desc-item">
                    <img src="@/assets/images/noun-annual-rate.svg" />
                    <span
                        >5 rate egale de
                        <span style="font-family: Raleway ExtraBold"
                            >{{ (price / 5).toFixed(2) }} lei</span
                        ></span
                    >
                </div>
                <div class="desc-item">
                    <img src="@/assets/images/noun-sale.svg" /><span
                        >fără dobândă, 0%</span
                    >
                </div>
                <div class="desc-item">
                    <img src="@/assets/images/noun-card.svg" /><span
                        >plăți sigure pe internet</span
                    >
                </div>
                <hr />
                <div style="display: flex" class="desc-item">
                    <div>
                        <img src="@/assets/images/noun-check.svg" />
                    </div>
                    <div class="info">
                        După adăugarea produsului în coș,
                        <span style="color: #008c00; text-decoration: underline"
                            >la finalizarea comenzii</span
                        >, veți putea selecta aceasta modalitate de plată, ulterior fiind
                        contactat de unul din colegii noștri pentru primirea linkului în
                        vederea efectuării plații.
                    </div>
                </div>
            </div>

            <!-- <div class="modal-footer">
        <button @click="starbtModal = false" type="button" class="btn btn-red">Cumpără în rate cu StarBt Credit</button>
      </div> -->
        </vue-final-modal>
        <div v-if="price >= 900 || rate" class="rate">
            <div v-if="price >= 900" class="row">
                <div class="col-sm-12">
                    Cumpără în 5 rate, fără dobândă, 0 % ——
                    <a @click="starbtModal = true">vezi detalii</a>
                </div>
            </div>
            <div v-if="price >= 1400" class="row">
                <div class="col-sm-12">
                    Cumpără în rate cu
                    <img style="height:22px;" class="rate-img" src="@/assets/images/tbibank.png" />
                    ——
                    <a @click="showModal = true">vezi detalii</a>
                </div>
            </div>
            <hr />
        </div>

        <!-- <img src="@/assets/images/product/calculator.png" /> -->
        <!-- <span> -->
        <!-- <span class="rate-title"  style="font-weight: 500">Cumpără în rate —— </span> -->
        <!-- <a class="rate-link" @click="showModal = true"> vezi detalii</a> -->
        <!-- <hr /> -->
        <!-- </span> -->
    </div>
</template>

<script>
// import ProductSubTitle from "@/components/product_page/ProductSubTitle.vue";
// import axios from "axios";

export default {
    data: () => ({
        showModal: false,
        starbtModal: false,
        rates: [
            { period: "6", rata: "-", total: "-" },
            { period: "12", rata: "-", total: "-" },
            { period: "18", rata: "-", total: "-" },
            { period: "24", rata: "-", total: "-" },
            { period: "36", rata: "-", total: "-" },
            { period: "48", rata: "-", total: "-" },
            { period: "60", rata: "-", total: "-" },
        ],
    }),
    props: ["price", "rate"],
    components: {
        // ProductSubTitle,
    },
    created() {
        // this.calculateRates();
        this.calculatePrices();
    },
    methods: {
        // calculateRates() {
        //     var price = this.price;
        //     var rates = this.rates;
        //     this.rates.forEach(function (rata, index) {
        //         axios
        //             .get("/api/v1/rate/" + rata.period + "/" + price)
        //             .then(function (response) {
        //                 if (response.data) {
        //                     rates[index].rata = response.data.mpay.toFixed(2);
        //                     rates[index].total = (
        //                         response.data.mpay * rata.period
        //                     ).toFixed(2);
        //                 }
        //             })
        //             .catch(function (error) {
        //                 alert(error);
        //             });
        //     });
        // },

        calculatePrices() 
        {
            var totalvalue = parseInt(this.price); // valoare bun
            var dobanda, perioada, comision, valasig, valasigurare, principal, CalculateInterest, calculatedPayments, x, monthly, monthlyPayment, interest, dueEndOrBeginning, futureValue, paymentsPerYear, presentValue, paymentAmount, FINANCIAL_MAX_ITERATIONS, FINANCIAL_PRECISION, y, y0, y1, x0, x1, f, i, rate;
            if (totalvalue < 1001) {
                this.rates = [{ period: "4", rata: "-", total: "-" }];
            }
            var rates = this.rates;
            this.rates.forEach(function (rata, index) {
                if (totalvalue < 1001) {
                    dobanda = 0;
                    perioada = 4;
                    comision = 40;
                    valasig = 0; //Valoare asigurare
                } else {
                    dobanda = 0.15;
                    perioada = parseInt(rata.period); // nr. de rate selectate de
                    comision = 170;
                    valasig = 0.0020; //Valoare asigurare
                }

                //calcul valoare asigurare
                valasigurare = valasig*(totalvalue+comision)*perioada;

                //componente valoare rata
                principal = (totalvalue+comision+valasigurare);
                CalculateInterest = dobanda/12;
                calculatedPayments = perioada;

                //formula valoare rata
                x = Math.pow(1 + CalculateInterest, calculatedPayments);
                monthly = (principal * x * CalculateInterest) / (x - 1);
                monthlyPayment = monthly.toFixed(2);
                
                //dae var
                interest = 0.1;
                dueEndOrBeginning = 0;
                futureValue = 0;
                paymentsPerYear = perioada;
                if (totalvalue < 1001){
                    presentValue =  totalvalue;
                    paymentAmount = -((totalvalue+comision)/4);
                }else{
                    presentValue =  (totalvalue+valasigurare);
                    paymentAmount = (monthlyPayment*-1);
                }
                                    
                //dae formula
                FINANCIAL_MAX_ITERATIONS = 128;//Bet accuracy with 128
                FINANCIAL_PRECISION = 0.0000001;//1.0e-8
                                
                y, y0, y1, x0, x1 = 0, f = 0, i = 0;
                rate = interest; // initiallizing rate to our guess interest 
                if (Math.abs(rate) < FINANCIAL_PRECISION) {
                    y = presentValue * (1 + paymentsPerYear * rate) + paymentAmount * (1 + rate * dueEndOrBeginning) * paymentsPerYear + futureValue;
                } else {
                    f = Math.exp(paymentsPerYear * Math.log(1 + rate));
                    y = presentValue * f + paymentAmount * (1 / rate + dueEndOrBeginning) * (f - 1) + futureValue;
                }
                y0 = presentValue + paymentAmount * paymentsPerYear + futureValue;
                y1 = presentValue * f + paymentAmount * (1 / rate + dueEndOrBeginning) * (f - 1) + futureValue;
                                
                // find root by Newton secant method
                i = x0 = 0.0;
                x1 = rate;
                while ((Math.abs(y0 - y1) > FINANCIAL_PRECISION) && (i < FINANCIAL_MAX_ITERATIONS)) {
                    rate = (y1 * x0 - y0 * x1) / (y1 - y0);
                    x0 = x1;
                    x1 = rate;
                                
                    if (Math.abs(rate) < FINANCIAL_PRECISION) {
                        y = presentValue * (1 + paymentsPerYear * rate) + paymentAmount * (1 + rate * dueEndOrBeginning) * paymentsPerYear + futureValue;
                    } else {
                        f = Math.exp(paymentsPerYear * Math.log(1 + rate));
                        y = presentValue * f + paymentAmount * (1 / rate + dueEndOrBeginning) * (f - 1) + futureValue;
                    }
                                
                    y0 = y1;
                    y1 = y;
                    ++i;
                }				
                // var dae = ((((1+rate) ** 12)-1)*100).toFixed(2);

                // document.getElementById("asigurare").innerText = valasigurare.toFixed(2);
                // document.getElementById("dae").innerText = dae;

                if (totalvalue < 1001){
                    rates[index].rata = (totalvalue+40)/4;
                    rates[index].total = (totalvalue+40).toFixed(2);
                }else{
                    rates[index].rata = monthlyPayment;
                    rates[index].total = (monthlyPayment*perioada).toFixed(2);
                }
            });
        },

    },
};
</script>

<style lang="scss" scoped>
$color: #fff;

::v-deep(.modal-container) {
    display: flex;
    justify-content: center;
    align-items: center;
}
::v-deep(.modal-content) {
    position: relative;
    display: flex;
    flex-direction: column;
    max-height: 90%;
    margin: 0 1rem;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 22px;
    background: $color;
    width: auto;
}
.modal__header {
    padding: 40px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: left;
    border: none;
}
.modal-title {
    font-weight: bold;
    font-family: "Raleway Bold";
    color: black;
}

.modal-title img {
    width: 148px;
    /* height: 51px; */
}

.modal-content {
    flex-grow: 1;
    overflow-y: auto;
}

.modal__content {
    padding: 40px;
    padding-top: 0px;
    padding-bottom: 0px;
}

#calculatorRate .modal-header {
    padding: 40px;
    padding-top: 30px;
    padding-bottom: 20px;

    text-align: left;
}

#calculatorRate .modal-title {
    font-family: "Raleway Bold";
    color: black;
}

#calculatorRate .modal-title img {
    width: 148px;
    /*height: 51px;*/
}

#calculatorRate .modal-body {
    padding: 40px;
    padding-top: 0px;
    padding-bottom: 0px;
    flex-grow: 1;
    overflow-y: auto;
}

#calculatorRate .modal-footer {
    justify-content: center;
    border: 0;
    padding-bottom: 10px;
}

#calculatorRate button.close {
    width: 40px;
    height: 40px;
    position: absolute;
    right: 15px;
}

#calculatorRate button.close span {
    color: black;
    font-size: 41px;
}

#calculatorRate .modal-footer button {
    pointer-events: none;
}

#calculatorRate table {
    border-collapse: collapse;
}
#calculatorRate table .table-head td {
    font-family: "Raleway Bold";
    font-size: 16px;
    /*font-weight: bold;*/
}
#calculatorRate table .table-body td {
    font-size: 13px;
}
#calculatorRate table td {
    padding: 20px;
    border: solid 1px rgb(151, 151, 151, 0.44);
}
#calculatorRate table tr:first-child td {
    border-top: 0;
}
#calculatorRate table tr td:first-child {
    padding-left: 0;
    border-left: 0;
}
#calculatorRate table tr:last-child td {
    border-bottom: 0;
}
#calculatorRate table tr td:last-child {
    border-right: 0;
}

.rate-title {
    font-family: "Montserrat Medium";
    font-size: 12px;
}

.rate-link {
    font-family: "Montserrat SemiBold";
    font-size: 12px;
}

.rate .row {
    padding-bottom: 8px;
}

.rate-img {
    height: 16px;
}

#calculatorRate .rate {
    font-family: 'Montserrat Bold';
    font-size: 12px;
}

@media (max-width: 991px) {
    .rate-tbi {
        margin-top: 20px;
        margin-bottom: 0px;
    }

    .rate-tbi hr {
        margin-top: 25px;
    }
}

@media (max-width: 768px) {
    #calculatorRate .close:after {
        display: none;
    }
}

@media (max-width: 576px) {
    #calculatorRate .modal-content {
        margin-top: 70px;
    }

    #calculatorRate .modal-header,
    #calculatorRate .modal-body {
        padding-left: 20px;
        padding-right: 20px;
    }

    #calculatorRate table .table-head td {
        font-size: 13px;
    }

    #calculatorRate table td {
        padding: 10px;
    }

    #calculatorRate table .table-body td {
        font-size: 10px;
    }
}

@media (max-width: 400px) {
    #calculatorRate .modal-title {
        font-size: 15px;
    }

    #calculatorRate .modal-title img {
        width: 80px;
    }
}

.modal__action {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    padding: 1rem 0 0;
}
.modal__close {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
}

// tbistyle
#tbimodal .desc-item {
    width: 560px;
    margin: 0 auto;
}

// starbtstyle
#starbtmodal .modal-title img {
    height: 30px;
    bottom: 8px;
    left: 5px;
    position: relative;
}

.desc-item {
    font-family: "Raleway SemiBold" !important;
    margin-bottom: 20px;
    color: black;
    font-size: 14px;
}

.desc-item:last-child {
    width: 430px;
}

#starbtmodal .modal-title {
    font-family: "Raleway Bold" !important;
    color: black;
    font-size: 24px;
}

#starbtmodal .modal__header {
    padding: 37px;
    padding-top: 45px;
    padding-bottom: 23px;
    text-align: left;
}

@media (max-width: 576px) {
    .desc-item:last-child,
    #tbimodal .desc-item {
        width: auto;
    }

    .rate-tbi a {
        font-size: 12px;
    }

    .rate-tbi img {
        margin-bottom: 0;
    }

    .rate-tbi hr {
        margin-top: 37px;
    }
    .rate-addintional-text {
        font-size: 12px;
    }
}
</style>

<style scoped>
.dark-mode div ::v-deep(.modal-content) {
    border-color: #2d3748;
    background-color: #1a202c;
}
.banks {
    margin-bottom: 30px;
}
.banks img {
    height: 27px;
}
.desc-item .info {
    font-family: Raleway Bold;
}
</style>
